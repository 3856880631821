import Vue from 'vue'
import VueRouter from 'vue-router'
import SuperLayout from "../layout/SuperLayout";
import SchoolLayout from "../layout/SchoolLayout";
import Main from '../layout/Main'

Vue.use(VueRouter)

export const commonRoutes = [{
    path: '/',
    name: 'Login',
    component: () =>
        import ('../views/Login'),
    meta: {
        title: '校园小邮局',
        role: [1, 2],
    }
}, ]

export const superRoutes = [{
    path: '/super',
    name: 'super',
    redirect: '/super/index',
    component: SuperLayout,
    children: [{
            path: '/super/index',
            name: 'superIndex',
            component: () =>
                import ('../views/super/Index'),
            meta: {
                title: '系统设置',
                role: 1,
                icon: '&#xe8b8;'
            }
        },
        {
            path: '/super/overview',
            name: 'superOverview',
            component: () =>
                import ('../views/super/OverView'),
            meta: {
                title: '概况',
                role: 1,
                icon: '&#xe89f;'
            }
        },
        {
            path: '/super/inside',
            name: 'superInside',
            component: () =>
                import ('../views/super/Inside'),
            meta: {
                title: '概况的一级内页',
                role: 1,
                parentName: ['/super/overview'],
                hidden: true,
            }
        },
        {
            path: '/super/goodManage',
            name: 'superGoodManage',
            redirect: '/super/goodManage/goodClass',
            component: Main,
            meta: {
                title: '商品管理',
                role: 1,
                icon: '&#xe888;'
            },
            children: [{
                    path: '/super/goodManage/goodClass',
                    name: 'superGoodManageGoodClass',
                    component: () =>
                        import ('../views/super/goodManage/GoodClass'),
                    meta: {
                        title: '商品分类',
                        role: 1,
                    },
                },
                {
                    path: '/super/goodManage/expressManage',
                    name: 'superGoodManageExpressManage',
                    component: () =>
                        import ('../views/super/goodManage/ExpressManage'),
                    meta: {
                        title: '快递管理',
                        role: 1,
                    },
                },
                {
                    path: '/super/goodManage/inside',
                    name: 'superGoodManageInside',
                    component: () =>
                        import ('../views/super/Inside'),
                    meta: {
                        title: '商品分类的二级内页',
                        role: 1,
                        parentName: ['/super/goodManage/goodClass'],
                        hidden: true,
                    },
                },
            ]
        },
        {
            path: '/super/postOfficeManage',
            name: 'superPostOfficeManage',
            component: () =>
                import ('../views/super/PostOfficeManage'),
            meta: {
                title: '小邮局管理',
                role: 1,
                icon: '&#xe900;'
            }
        },
        {
            path: '/super/dataManage',
            name: 'superDataManage',
            redirect: '/super/dataManage/receiving',
            component: Main,
            meta: {
                title: '数据管理',
                role: 1,
                icon: '&#xe904;'
            },
            children: [{
                    path: '/super/dataManage/receiving',
                    name: 'superDataManageReceiving',
                    component: () =>
                        import ('../views/super/dataManage/Receiving'),
                    meta: {
                        title: '收件数据',
                        role: 1,
                    },
                },
                {
                    path: '/super/dataManage/sending',
                    name: 'superDataManageSending',
                    component: () =>
                        import ('../views/super/dataManage/Sending'),
                    meta: {
                        title: '寄件数据',
                        role: 1,
                    },
                },
                {
                    path: '/super/dataManage/goods',
                    name: 'superDataManageGoods',
                    component: () =>
                        import ('../views/super/dataManage/Goods'),
                    meta: {
                        title: '商品数据',
                        role: 1,
                    },
                },
                {
                    path: '/super/dataManage/market',
                    name: 'superDataManageMarket',
                    component: () =>
                        import ('../views/super/dataManage/Market'),
                    meta: {
                        title: '营销数据',
                        role: 1,
                    },
                },
            ]
        },
    ]
}]

export const schoolRoutes = [{
    path: '/school',
    name: 'school',
    redirect: '/school/index',
    component: SchoolLayout,
    children: [{
            path: '/school/index',
            name: 'schoolIndex',
            component: () =>
                import ('../views/school/Index'),
            meta: {
                title: '概况',
                role: 2,
                icon: '&#xe89f;'
            }
        },
        {
            path: '/school/expressManage',
            name: 'schoolExpressManage',
            redirect: '/school/expressManage/receiving',
            component: Main,
            meta: {
                title: '快递管理',
                role: 2,
                icon: '&#xe883;'
            },
            children: [{
                    path: '/school/expressManage/receiving',
                    name: 'schoolExpressManageReceiving',
                    component: () =>
                        import ('../views/school/expressManage/Receiving'),
                    meta: {
                        title: '取件管理',
                        role: 2,
                    },
                },
                {
                    path: '/school/expressManage/sending',
                    name: 'schoolExpressManageSending',
                    component: () =>
                        import ('../views/school/expressManage/Sending'),
                    meta: {
                        title: '寄件管理',
                        role: 2,
                    },
                },
                {
                    path: '/school/expressManage/sending/see',
                    name: 'schoolExpressManageSendingSee',
                    component: () =>
                        import ('../views/school/expressManage/SendingSee'),
                    meta: {
                        title: '寄件详情',
                        role: 2,
                        parentName: ['/school/expressManage/sending'],
                        hidden: true,
                    },
                },
                {
                    path: '/school/expressManage/sending/edit',
                    name: 'schoolExpressManageSendingEdit',
                    component: () =>
                        import ('../views/school/expressManage/SendingEdit'),
                    meta: {
                        title: '编辑寄件',
                        role: 2,
                        parentName: ['/school/expressManage/sending'],
                        hidden: true,
                    },
                },
            ]
        },
        {
            path: '/school/pointsManage',
            name: 'schoolPointsManage',
            redirect: '/school/pointsManage/rewardPoints',
            component: Main,
            meta: {
                title: '积分管理',
                role: 2,
                icon: '&#xe897;'
            },
            children: [{
                    path: '/school/pointsManage/rewardPoints',
                    name: 'schoolPointsManageRewardPoints',
                    component: () =>
                        import ('../views/school/pointsManage/RewardPoints'),
                    meta: {
                        title: '积分换购',
                        role: 2,
                    },
                },
                {
                    path: '/school/pointsManage/pointsRules',
                    name: 'schoolPointsManagePointsRules',
                    component: () =>
                        import ('../views/school/pointsManage/PointsRules'),
                    meta: {
                        title: '积分规则',
                        role: 2,
                    },
                },
                {
                    path: '/school/pointsManage/rewardPoints/detail',
                    name: 'schoolPointsManageRewardPointsDetail',
                    component: () =>
                        import ('../views/school/pointsManage/RewardPointsDetail'),
                    meta: {
                        title: '积分换购-详情',
                        role: 2,
                        parentName: ['/school/pointsManage/rewardPoints'],
                        hidden: true,
                    },
                },
            ]
        },
        {
            path: '/school/storeManage',
            name: 'schoolStoreManage',
            redirect: '/school/storeManage/storeInfo',
            component: Main,
            meta: {
                title: '店铺管理',
                role: 2,
                icon: '&#xe90e;'
            },
            children: [{
                    path: '/school/storeManage/storeInfo',
                    name: 'schoolStoreManageStoreInfo',
                    component: () =>
                        import ('../views/school/storeManage/StoreInfo'),
                    meta: {
                        title: '店铺信息',
                        role: 2,
                    },
                },
                {
                    path: '/school/storeManage/goodsManage',
                    name: 'schoolStoreManageGoodsManage',
                    component: () =>
                        import ('../views/school/storeManage/GoodsManage'),
                    meta: {
                        title: '商品管理',
                        role: 2,
                    },
                },
                {
                    path: '/school/storeManage/goodsManage/add',
                    name: 'schoolStoreManageGoodsManageAdd',
                    component: () =>
                        import ('../views/school/storeManage/GoodsManageAdd'),
                    meta: {
                        title: '商品管理-添加商品',
                        role: 2,
                        hidden: true,
                        parentName: ['/school/storeManage/goodsManage']
                    }
                },

                {
                    path: '/school/storeManage/goodsManage/stock',
                    name: 'schoolStoreManageGoodsManageDetail',
                    component: () =>
                        import ('../views/school/storeManage/GoodsManageStock'),
                    meta: {
                        title: '商品管理-仓库商品',
                        role: 2,
                        hidden: true,
                        parentName: ['/school/storeManage/goodsManage']
                    }
                }, {
                    path: '/school/storeManage/goodsManage/recycle',
                    name: 'schoolStoreManageGoodsManageRecycle',
                    component: () =>
                        import ('../views/school/storeManage/GoodsManageRecycle'),
                    meta: {
                        title: '商品管理-商品回收站',
                        role: 2,
                        hidden: true,
                        parentName: ['/school/storeManage/goodsManage']
                    }
                },
                {
                    path: '/school/storeManage/storeDynamic',
                    name: 'schoolStoreManageStoreDynamic',
                    component: () =>
                        import ('../views/school/storeManage/StoreDynamic'),
                    meta: {
                        title: '店铺动态',
                        role: 2,
                    },
                },
                {
                    path: '/school/storeManage/storeDynamic/detail',
                    name: 'schoolStoreManageStoreDynamicDetail',
                    component: () =>
                        import ('../views/school/storeManage/StoreDynamicDetail'),
                    meta: {
                        title: '店铺动态-详情',
                        role: 2,
                        parentName: ['/school/storeManage/storeDynamic'],
                        hidden: true,
                    },
                },
                {
                    path: '/school/storeManage/storeDynamic/recycle',
                    name: 'schoolStoreManageStoreDynamicRecycle',
                    component: () =>
                        import ('../views/school/storeManage/StoreDynamicRecycle'),
                    meta: {
                        title: '店铺动态-回收站',
                        role: 2,
                        parentName: ['/school/storeManage/storeDynamic'],
                        hidden: true,
                    },
                },
            ]
        },
        {
            path: '/school/bannerManage',
            name: 'schoolBannerManage',
            component: () =>
                import ('../views/school/BannerManage'),
            meta: {
                title: 'banner管理',
                role: 2,
                icon: '&#xe8d3;'
            }
        },
        {
            path: '/school/userManage',
            name: 'schoolUserManage',
            component: () =>
                import ('../views/school/UserManage'),
            meta: {
                title: '用户管理',
                role: 2,
                icon: '&#xe8c8;'
            }
        },
    ]
}]

const createRouter = () => new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    base: process.env.BASE_URL,
    routes: commonRoutes.concat(superRoutes).concat(schoolRoutes)
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router