import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  getters: {
    superMenu: state => state.superMenu,
    schoolMenu: state => state.schoolMenu,
  },
  state: {
    superMenu: [],
    schoolMenu: [],
  },
  mutations: {
    SET_SUPER_ROUTES: (state, routes) => {
      state.superMenu = routes
    },
    SET_SCHOOL_ROUTES: (state, routes) => {
      state.schoolMenu = routes
    },
  },
  actions: {
    generateSuperRoutes({ commit }, superRoutes) {
      commit('SET_SUPER_ROUTES', superRoutes)
    },
    generateSchoolRoutes({ commit }, schoolRoutes) {
      commit('SET_SCHOOL_ROUTES', schoolRoutes)
    },
  },
  modules: {
  }
})
