import http from "../utils/http";

export const login = (params) => {
    return http.post('login', params)
}
export const commonInfoAppName = () => {
    return http.get('common_info/app_name')
}
export const dataManageAdminIndex = () => {
    return http.get('data_manage/adminIndex')
}
export const courierIndex = (params) => {
    return http.get('courier/index', {params: params})
}
export const courierRead = (id) => {
    return http.get('courier/read/'+ id)
}
export const courierCreate = (params) => {
    return http.post('courier/courierCreate', params)
}
export const courierUpdate = (params) => {
    return http.post('courier/update', params)
}
export const courierDel = (id) => {
    return http.post('courier/del/'+ id)
}
export const settingIndex = () => {
    return http.get('setting/index')
}
export const settingUpdate = (params) => {
    return http.post('setting/update', params)
}
export const campanyIndex = (params) => {
    return http.get('campany/index', {params: params})
}
export const campanyUpdate = (params) => {
    return http.post('campany/update', params)
}
export const categoryList = (params) => {
    return http.get('category/categoryList', {params: params})
}
export const addCategory = (params) => {
    return http.post('category/addCategory', params)
}
export const delCategory = (params) => {
    return http.post('category/delCategory', params)
}
export const dataManageCourierIndex = (params) => {
    return http.get('data_manage/courierIndex', {params: params})
}
export const data_manage_takeList = (params) => {
    return http.get('data_manage/takeList', {params: params})
}
export const data_manage_mailList = (params) => {
    return http.get('data_manage/mailList', {params: params})
}
export const goodsListAdmin = (params) => {
    return http.get('goods/goodsListAdmin', {params: params})
}
export const goods_adminDelGoods = (params) => {
    return http.post('goods/adminDelGoods', params)
}
export const goods_downGoods = (params) => {
    return http.post('goods/downGoods', params)
}
export const talk_delTalk = (params) => {
    return http.post('talk/delTalk', params)
}
export const talk_talkList = (params) => {
    return http.get('talk/talkList', {params: params})
}
export const mail_takeIndex = (params) => {
    return http.get('mail_take/index', {params: params})
}
export const courier_companyIndex = () => {
    return http.get('courier_company/index')
}
export const mail_takeCreate = (params) => {
    return http.post('mail_take/create', params)
}
export const mail_takeChange = (params) => {
    return http.post('mail_take/change', params)
}
export const mail_takeRemind = (id) => {
    return http.post('mail_take/remind/' + id)
}
export const mail_takeExport = (params) => {
    return http.get('mail_take/export', {params: params})
}
export const mail_takeConfirm = (params) => {
    return http.post('mail_take/confirm', params)
}
export const mail_manageIndex = (params) => {
    return http.get('mail_manage/index', {params: params})
}
export const account_courier_index = (params) => {
    return http.get('account_courier/index', {params: params})
}
export const account_courier_edit = (params) => {
    return http.post('account_courier/edit', params)
}
export const talk_recycleBin = (params) => {
    return http.get('talk/recycleBin', {params: params})
}
export const talk_restoreTalk = (params) => {
    return http.post('talk/restoreTalk', params)
}
export const talk_uploadCourierTalkImg = (params) => {
    return http.post('talk/uploadCourierTalkImg', params)
}
export const goods_goodsList = (params) => {
    return http.get('goods/goodsList', {params: params})
}

export const goods_delgoods=(params)=>{
    return http.post('goods/delGoods',params)
}
export const goods_getgoods=(params)=>{ 
    return http.get('goods/getGoods',{params:params})
}
export const goodsUploadGoodsImg = (params) => {
    return 'goods/uploadGoodsImg'
}
export const goods_addGoods=(params)=>{
    return http.post('goods/addGoods',params)
}
export const goods_putGoods=(params)=>{
    return http.get('goods/putGoods',{params:params})
}
export const goods_delExchangeGoods=(params)=>{
    return http.post('exchange_goods/delExchangeGoods',params)
}
export const goods_goodsRecycleBin=(params)=>{
    return http.get('goods/goodsRecycleBin',{params:params})
}
export const goods_reCycleGoods=(params)=> {
    return http.get('goods/reCycleGoods', {params: params})
}
export const talk_getDetail = (params) => {
    return http.get('talk/getDetail', {params: params})
}
export const talk_addCourierTalk = (params) => {
    return http.post('talk/addCourierTalk', params)
}
export const mail_manageInfo = (id) => {
    return http.get('mail_manage/info/' + id)
}
export const mail_manageChange = (params) => {
    return http.post('mail_manage/change', params)
}
export const mail_manageEdit = (params) => {
    return http.post('mail_manage/edit', params)
}
export const mail_manageDel = (id) => {
    return http.post('mail_manage/del/' + id)
}
export const bannerBannerList = (params) => {
    return http.get('banner/bannerList', {params: params})
}
export const bannerUploadBannerImg = (params) => {
    return 'banner/uploadBannerImg'
}
export const bannerAddBanner = (params) => {
    return http.post('banner/addBanner', params)
}
export const bannerDelBanner = (params) => {
    return http.post('banner/delBanner', params)
}
export const courier_shop_edit = (params) => {
    return http.post('courier_shop/edit', params)
}
export const courier_shop_info = (params) => {
    return http.get('courier_shop/info', {params: params})
}
export const exchangeGoodsListAdmin = (params) => {
    return http.get('exchange_goods/exchangeGoodsListAdmin', {params: params})
}
export const getExchangeGoodsAdmin = (params) => {
    return http.get('exchange_goods/getExchangeGoodsAdmin', {params: params})
}
export const exchange_goods_addExangeGoods = (params) => {
    return http.post('exchange_goods/addExangeGoods', params)
}
export const exchange_goods_delExchangeGoods = (params) => {
    return http.post('exchange_goods/delExchangeGoods', params)
}
export const points_config_setPointsConfit = (params) => {
    return http.post('points_config/setPointsConfit', params)
}
export const points_config_getPointsConfit = (params) => {
    return http.get('points_config/getPointsConfit', {params: params})
}
export const points_pointsRecordList = (params) => {
    return http.get('points/pointsRecordList', {params: params})
}