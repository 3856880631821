import router from './router'
import store from './store'
import {commonRoutes, superRoutes, schoolRoutes} from '@/router'

const whiteList = ['Login'] // 不需要登录就可以访问的路由白名单

router.beforeEach(async (to, from, next) => {
    // set page title
    document.title = to.meta.title

    // 定义2个端各自的token，方便走各自的路由逻辑，互不干扰
    const rolesToken = {
        1: 'superToken',
        2: 'schoolToken',
    }

    const hasToken = localStorage.getItem(rolesToken[to.meta.role]) // 获取当前访问客户端的token

    if (!hasToken) {
        /* has no token*/
        if (whiteList.indexOf(to.name) !== -1) {
            next()
            return
        }
        const loginRoute = commonRoutes.find(item => item.meta.role.includes(to.meta.role))
        if (loginRoute) {
            next({path: loginRoute.path})
        }

    }

    // 如果访问的路由存在对应的token，走各自流程
    switch (to.meta.role) {
        case 1:
            const superMenu = store.getters.superMenu && store.getters.superMenu.length > 0;
            if (!superMenu) {
                store.dispatch('generateSuperRoutes', superRoutes)
            }
            next()
            break;
        case 2:
            // 获取schoolMenu是否存在
            const schoolMenu = store.getters.schoolMenu && store.getters.schoolMenu.length > 0;
            if (!schoolMenu) {
                store.dispatch('generateSchoolRoutes', schoolRoutes)
            }
            next()
            break;
        default:
            // 二个端共用的路由，直接访问
            next()
    }
})

router.afterEach(() => {
})