<template>
    <div class="container">
        <div class="header">
            <div class="logo">{{webName}}</div>
            <div class="right">
                <div class="username">{{nickname}}</div>
                <div @click="logoutBtn" class="logout-btn">退出</div>
            </div>
        </div>

        <div class="content">
            <el-scrollbar class="menu-wrapper">
                <el-menu :default-active="activeIndex" router class="menu-box">
                    <template v-for="(item, index) in schoolMenu">
                        <el-submenu v-if="item.hasOwnProperty('children')" :index="item.path" :key="index">
                            <template slot="title">
                                <i class="iconfont" v-html="item.meta.icon"></i>
                                <span>{{item.meta.title}}</span>
                            </template>
                            <template v-for="(levelTwo, levelIndex) in item.children">
                                <el-menu-item v-if="!levelTwo.meta.hidden" :index="levelTwo.path" :key="levelIndex">
                                    {{levelTwo.meta.title}}
                                </el-menu-item>
                            </template>
                        </el-submenu>
                        <template v-else>
                            <el-menu-item v-if="!item.meta.hidden" :index="item.path" :key="index">
                                <i class="iconfont" v-html="item.meta.icon"></i>
                                <span slot="title">{{item.meta.title}}</span>
                            </el-menu-item>
                        </template>
                    </template>
                </el-menu>
            </el-scrollbar>
            <router-view class="right-content" />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { commonInfoAppName } from '../config/apis'

    export default {
        name: "SchoolLayout",
        data() {
            return {
                activeIndex: '',
                webName: '',
                nickname: localStorage.getItem('nickname')
            }
        },
        computed: mapState({
            schoolMenu: state => state.schoolMenu[0].children
        }),
        watch: {
            $route(route) {
                // this.activeIndex = route.path
            }
        },
        created() {
            if (this.$route.meta.hasOwnProperty('parentName')) {
                this.activeIndex = this.$route.meta.parentName[0]
            } else {
                this.activeIndex = this.$route.path
            }
        },
        mounted() {
            this.getWebInfo()
        },
        methods: {
            getWebInfo() {
                commonInfoAppName().then(res => {
                    this.webName = res.data.name
                }).catch(err => {})
            },
            // 退出
            logoutBtn() {
                this.$message.success('退出成功！')
                localStorage.removeItem('role')
                localStorage.removeItem('id')
                localStorage.removeItem('username')
                localStorage.removeItem('nickname')
                localStorage.removeItem('avatar')
                localStorage.removeItem('schoolToken')
                localStorage.removeItem('domain_url')
                this.$router.push('/')
            }
        }
    }
</script>

<style scoped lang="scss">
    .container {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .header {
            height: 60px;
            display: flex;
            position: relative;
            border-bottom: 1px solid #e6e6e6;
            font-size: 16px;
            .logo {
                width: 250px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .right {
                position: absolute;
                right: 0;
                display: flex;
                .username {
                    line-height: 60px;
                }
                .logout-btn {
                    line-height: 60px;
                    cursor: pointer;
                    padding: 0 20px;
                    &:hover {
                        color: #409eff;
                    }
                }
            }
        }
        .content {
            height: 1%;
            flex: 1;
            display: flex;
            ::v-deep .menu-box {
                width: 250px;
                border-right: none;
                .el-menu-item, .el-submenu__title {
                    .iconfont {
                        font-size: 18px;
                        margin-right: 6px;
                    }
                    span {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    ::v-deep .right-content {
        width: 1%;
        flex: 1;
        background: #F7F8FC;
        & > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    ::v-deep .menu-wrapper {
        height: 100%;
        border-right: solid 1px #e6e6e6;
        & > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
</style>