import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission'
import api from "./config/api"
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import tinymce from 'tinymce'
import VueTinymce from "@packy-tang/vue-tinymce"
import 'assets/style/index.scss'
import 'assets/style/iconfont.css'
Vue.config.productionTip = false
Vue.prototype.$api = api;
Vue.prototype.$tinymce = tinymce
Vue.use(ElementUI);
Vue.use(VueTinymce);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
